<template>
  <div class="notications">
    <a-layout>
      <a-layout-header :style="{ position: 'fixed', zIndex: 1, width: '100%' }">
        <Header />
      </a-layout-header>
      <a-layout>
        <Sidebar />
        <a-layout-content>
          <div>
            <a-row>
              <a-col :span="24">
                <h2 class="pageTittle">Notifications</h2>
              </a-col>
              <a-col :span="24">
                <ul class="scrollNext" :scroll="height ? { y:height } : { x:900, y:'calc(100vh - 470px)' }">
                  <div v-for="(notification, index) in notifications" :key="index" style="margin: 0 0 15px;">
                    <li class="listing " v-for="(notf, index) in notification.value" :key="index" :class="notf.Isread ? 'read' : 'unread'">
                      <router-link class="d-flex align-items-center" :to="notf.type == 'Appointment' || notf.type == 'task' ? '': '/communications'" @click="isReadNotification(notf.id, notf.type,notf.type_id,notification.date)">
                        <div class="flex-grow-1 ms-3 summary">
                          <h3>{{ notf.title }}</h3>
                          <p>{{ notf.body }}</p>
                          <br />
                          <strong class="" v-if="notf.time">{{
                            dateOnlyFormat(date) === dateOnlyFormat(notf.time)
                            ? ""
                            : dateOnlyFormat(notf.time)
                          }}</strong
                          >&nbsp;
                          <strong class="" v-if="notf.time">{{
                            meridiemFormatFromTimestamp(notf.time)
                          }} </strong>
                        </div>
                      </router-link>
                    </li>
                  </div>
                  <!-- <li class="listing unread">
                    <a class="d-flex align-items-center" href="#">
                      <div class="flex-grow-1 ms-3 summary">
                        <p>Just a reminder that you have appoinment</p>
                        <span class="date">20 minutes ago</span>
                      </div>
                    </a>
                  </li>
                  <li class="listing read">
                    <a class="d-flex align-items-center" href="#">
                      <div class="flex-grow-1 ms-3 summary">
                        <p>Just a reminder that you have appoinment</p>
                        <span class="date">20 minutes ago</span>
                      </div>
                    </a>
                  </li> -->
                </ul>
              </a-col>
            </a-row>
            <Loader />
          </div>
          <AppointmentDetails v-if="isAppointment"  v-model:visible="isAppointment" @closeModal="closeModal(event)" />
          <TaskDetails v-model:visible="taskVisibleView"  :taskId="taskID" :onlyView="onlyView"/>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { onMounted,computed,ref,defineAsyncComponent } from 'vue';
import { useStore } from "vuex";
import Header from "../layout/header/Header";
import Sidebar from "../layout/sidebar/Sidebar";
import {
  meridiemFormatFromTimestamp,
  dateOnlyFormat,
} from "@/commonMethods/commonMethod";
import AppointmentDetails from "@/components/modals/AppointmentDetails"
import Loader from "@/components/loader/Loader"

export default {
  components: {
    Header,
    Sidebar,
    AppointmentDetails,
    Loader,
    TaskDetails: defineAsyncComponent(() => import("@/components/tasks/modals/TaskDetails.vue"))
  },
  setup() {
    const searchAll = ref(false)
    const store = useStore();
    const isAppointment =ref(false);
    const taskID = ref()
    const onlyView = ref(true)
    const date = Math.round(+new Date() / 1000);
    const taskVisibleView = ref(false)
    const meta = store.getters.notificationsRecords.value;
    let data = [];

    const notifications = computed(() => {
      return store.state.common.getNotifications;
    });
    
    let scroller = "";
    let dateFilter = '&fromDate=&toDate='
    let filter = ''
    onMounted(() => {
      var tableContent = document.querySelector(".scrollNext");
      store.dispatch("getNotifications")
      tableContent.addEventListener("scroll", (event) => {
        let maxScroll = event.target.scrollHeight - event.target.clientHeight;
        let currentScroll = event.target.scrollTop + 2;
        if (currentScroll >= maxScroll) {
          let current_page = meta.notificationsMeta.current_page + 1;
          if (current_page <= meta.notificationsMeta.total_pages) {
            data = meta.getNotifications;
            scroller = maxScroll;
            store.state.common.notificationsMeta = ''
            if(!searchAll.value) {
              console.log('scrollNext', data)
              store.dispatch("getNotifications", "?page=" + current_page + dateFilter + filter + store.getters.searchTable.value + store.getters.orderTable.value.data).then(() => {
                loadMoredata();
              });
            }
          }
        }
      });
      // store.dispatch("getNotifications")
    })

    function loadMoredata() {
      const newData = meta.getNotifications;
      console.log('newData', newData)
      newData.forEach((element) => {
        data.push(element);
      });
      console.log('scrollNext', data)
      meta.getNotifications = data;
      var tableContent = document.querySelector(".scrollNext");
      setTimeout(() => {
        tableContent.scrollTo(0, scroller);
      }, 50);
    }

    const isReadNotification = (id, type,typeId,date) => {
       let object = notifications.value.find(obj => obj?.date === date);
        let isreadObject = object?.value.find(obj =>obj?.id ==id)
        isreadObject.Isread = 1
      if (type == "Appointment") {
        isAppointment.value=true
        store.dispatch("appointmentDetails",typeId)
        notifications.value
      }
      else if(type == "task"){
        store.dispatch('editTask', {
                id: typeId
            })
            taskID.value = typeId
            taskVisibleView.value = true
            
      } 
      store.dispatch("isReadUpdateNotification", id).then(()=>{
        
      })
      
    };

    function closeModal(status){
      isAppointment.value=status
    }
    return{
      closeModal,
      isAppointment,
      isReadNotification,
      notifications,
      meridiemFormatFromTimestamp,
      dateOnlyFormat,
      date,
      taskVisibleView,
      taskID,
      onlyView
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";

.notications {
  ul {
    list-style: none;
    padding: 0;
    max-height: calc(100vh - 185px);
    overflow: auto;
    .listing {
      background-color: $gray-color-100;
      border-radius: 8px;
      margin: 0 0 15px;
      &:last-child {
        margin: 0;
      }
      &.read {
        a {
          text-decoration: none;
        padding: 10px 15px;
        color: #444;
        }
      }
      &.unread {
        background-color: $secondary-color;
      }
      a {
        
        text-decoration: none;
        padding: 10px 15px;
        color: #444;
        .summary {
          p {
            
            font-size: 14px;
            line-height: 18px;
          }
          .date {
            font-size: 12px;
            color: #aaa;
          }
        }
      }
    }
  }
}
</style>
